import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/1stle-logo.png";
import LoadingCircle from "../utility/LoadingCircle";

const Unverified = () => {
	const { isLoading, isAuthenticated, user, logout } = useAuth0();
	const navigate = useNavigate();
	useEffect(() => {
		if (!isLoading) {
			if (isAuthenticated) {
				if (user && user.email_verified) {
					navigate("/dashboard");
				}
			}
		}
	}, [isAuthenticated, isLoading, navigate, user]);

	const relogin = () => {
		logout({ logoutParams: { returnTo: window.location.origin + "/login" } });
		navigate("/login");
	};

	return (
		<div className="fixed top-0 left-0 right-0 bottom-0 bg-black">
			<div className="flex w-full h-full justify-center items-center ">
				<div className="bg-white p-4 rounded-lg">
					{isLoading ? (
						<div className="flex flex-col items-center">
							<LoadingCircle />
							<p>Verifying your account...</p>
						</div>
					) : (
						<>
							<div className="bg-black p-2 rounded-lg mb-4 flex justify-center">
								<img src={logo} alt="home" className="w-auto h-12" />
							</div>
							<p>A verification email has been sent to {isLoading ? "your email" : user.email}.</p>
							<p>Please verify your email to continue.</p>
							<div className="flex justify-center">
								<button onClick={relogin} className="bg-blue-500 hover:bg-blue-700 mt-4 text-white p-2 rounded-lg">
									I have verified my account
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Unverified;
