import { NavLink, Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscTriangleUp } from "react-icons/vsc";
import { useEffect, useState } from "react";
import homeImg from "../assets/1stle-logo.png";
import { useAuth0 } from "@auth0/auth0-react";
const Nav = ({
	isAdmin,
	wordBankAccess,
	requestAccess,
	templateAccess,
	setTemplateAccess,
	setRequestAccess,
	setWordBankAccess,
	setIsAdmin,
	setToken,
	setUserId,
	isPublicStorage,
	setIsPublicStorage,
	setReportAccess,
	reportAccess,
	hasUnsavedFormData,
	setHasUnsavedFormData,
}) => {
	const navigate = useNavigate();
	const [menuOpen, setMenuOpen] = useState(false);
	const { logout, user } = useAuth0();

	const logOut = (e) => {
		if (typeof hasUnsavedFormData !== "undefined" && hasUnsavedFormData === true) {
			let confirmationResult = window.confirm("You have unsaved changes, are you sure you want to leave?");
			if (confirmationResult === true) {
				setHasUnsavedFormData(false);
			} else {
				return;
			}
		}
		setIsAdmin(false);
		setToken("");
		setWordBankAccess(false);
		setRequestAccess(false);
		setTemplateAccess(false);
		setUserId(-1);
		setIsPublicStorage(false);
		setReportAccess(false);
		localStorage.removeItem("token");
		localStorage.removeItem("admin");
		localStorage.removeItem("hasWordBankAccess");
		localStorage.removeItem("templateAccess");
		localStorage.removeItem("requestAccess");
		localStorage.removeItem("userId");
		localStorage.removeItem("isPublicStorage");
		localStorage.removeItem("reportAccess");
		localStorage.removeItem("username");
		if (typeof setHasUnsavedFormData !== "undefined") {
			setHasUnsavedFormData(false);
		}
		logout({ logoutParams: { returnTo: window.location.origin + "/login" } });
	};

	const checkForUnsavedData = (e) => {
		if (typeof hasUnsavedFormData !== "undefined" && hasUnsavedFormData === true) {
			let confirmationResult = window.confirm("You have unsaved changes, are you sure you want to leave?");
			if (confirmationResult === true) {
				setHasUnsavedFormData(false);
			} else {
				e.preventDefault();
			}
		}
	};

	const checkUnsavedAndCloseMenu = (e) => {
		setMenuOpen(false);
		checkForUnsavedData(e);
	};

	if (!user) {
		return;
	}

	return (
		<nav>
			<div className="text-center bg-black text-white p-4 flex justify-between md:items-center">
				<NavLink to="/dashboard" onClick={checkForUnsavedData}>
					<img src={homeImg} alt="home" className="w-auto h-12" />
				</NavLink>
				<div className="hidden md:flex md:gap-3 md:items-center">
					{(wordBankAccess || isAdmin) && !isPublicStorage ? (
						<Link to="/wordbank" onClick={checkForUnsavedData}>
							Word Bank
						</Link>
					) : null}
					<Link to="/orders?page=1">Orders</Link>
					{(templateAccess || isAdmin) && !isPublicStorage ? (
						<Link to="/templates?page=1&archived=0&query=&type=all&model=all&portfolio=all" onClick={checkForUnsavedData}>
							Templates
						</Link>
					) : null}
					{user["https://claim.krummycapture/claim"]["clocking_notes_permission"] || user["https://claim.krummycapture/claim"]["admin"] ? (
						<Link to="/clocking-notes" onClick={checkUnsavedAndCloseMenu}>
							Clocking Notes
						</Link>
					) : null}
					{/* {(requestAccess || isAdmin) && !isPublicStorage ? (
						<Link to="/request-list" onClick={checkForUnsavedData}>
							Requests
						</Link>
					) : null} */}
					<Link to="/projects/search?query= &page=1&contract=&stage=&template=&model=project.task" onClick={checkForUnsavedData}>
						Projects
					</Link>
					{(reportAccess || isAdmin) && !isPublicStorage ? (
						<Link to="/reports" onClick={checkForUnsavedData}>
							Reports
						</Link>
					) : null}
					<p className="cursor-pointer" onClick={(e) => logOut(e)}>
						Log out
					</p>
				</div>
				<div className="flex flex-col items-end justify-center md:hidden">
					{menuOpen ? (
						<VscTriangleUp
							onClick={() => setMenuOpen((prev) => !prev)}
							className="text-4xl cursor-pointer border border-white rounded p-1 select-none"
						/>
					) : (
						<GiHamburgerMenu
							onClick={() => setMenuOpen((prev) => !prev)}
							className="text-4xl cursor-pointer border border-white rounded p-1 select-none"
						/>
					)}
				</div>
			</div>
			<div className={`bg-black text-white flex flex-col ${menuOpen ? "pr-4 pb-4" : " "} items-end justify-center md:hidden`}>
				{menuOpen ? (
					<div className="flex flex-col text-left gap-4">
						{(wordBankAccess || isAdmin) && !isPublicStorage ? (
							<Link to="/wordbank" onClick={checkUnsavedAndCloseMenu}>
								Word Bank
							</Link>
						) : null}

						<Link to="/orders?page=1">Orders</Link>
						{(templateAccess || isAdmin) && !isPublicStorage ? (
							<Link to="/templates?page=1&archived=0&query=&type=all&model=all&portfolio=all" onClick={checkUnsavedAndCloseMenu}>
								Templates
							</Link>
						) : null}

						{user["https://claim.krummycapture/claim"]["clocking_notes_permission"] || user["https://claim.krummycapture/claim"]["admin"] ? (
							<Link to="/clocking-notes" onClick={checkUnsavedAndCloseMenu}>
								Clocking
								<br />
								Notes
							</Link>
						) : null}
						{/* {(requestAccess || isAdmin) && !isPublicStorage ? (
							<Link to="/request-list" onClick={checkUnsavedAndCloseMenu}>
								Requests
							</Link>
						) : null} */}
						<Link to="/projects/search?query= &page=1&contract=&stage=&template=&model=project.task" onClick={checkUnsavedAndCloseMenu}>
							Projects
						</Link>
						{(reportAccess || isAdmin) && !isPublicStorage ? (
							<Link to="/reports" onClick={checkUnsavedAndCloseMenu}>
								Reports
							</Link>
						) : null}
						<p className="cursor-pointer" onClick={(e) => logOut(e)}>
							Log out
						</p>
					</div>
				) : null}
			</div>
		</nav>
	);
};

export default Nav;
