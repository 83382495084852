import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Nav from "./Nav";
import { IoArrowBackCircle } from "react-icons/io5";
import LoadingCircle from "./utility/LoadingCircle";
import { useAuth0 } from "@auth0/auth0-react";

const WrapperRoute = ({
	setToken,
	setIsAdmin,
	token,
	api,
	adminRequired,
	isAdmin,
	wordBankAccess,
	setWordBankAccess,
	wordBankAccessRequired,
	setTemplateAccess,
	setRequestAccess,
	templateAccess,
	requestAccess,
	templateAccessRequired,
	requestAccessRequired,
	userId,
	setUserId,
	isPublicStorage,
	setIsPublicStorage,
	reportAccess,
	setReportAccess,
	reportAccessRequired,
	hasUnsavedFormData,
	setHasUnsavedFormData,
	proposalAccess,
	setProposalAccess,
	exportAccess,
	setExportAccess,
	clearRecordAccess,
	setClearRecordAccess,
}) => {
	const navigate = useNavigate();
	let location = useLocation();
	const [lastLocation, setLastLocation] = useState(location);
	const [loggedIn, setLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);
	const { isLoading, isAuthenticated, user, logout } = useAuth0();

	useEffect(() => {
		if (user) {
			if (!user.email_verified) {
				navigate("unverified-user");
			}
		}
	}, [navigate, user]);

	useEffect(() => {
		if (isLoading) {
			return;
		}
		if (isAuthenticated) {
			setLoggedIn(true);
			let res = user["https://claim.krummycapture/claim"];
			if (!res) {
				setIsAdmin(false);
				setToken("");
				setWordBankAccess(false);
				setRequestAccess(false);
				setTemplateAccess(false);
				setUserId(-1);
				setIsPublicStorage(false);
				setReportAccess(false);
				setExportAccess(false);
				setClearRecordAccess(false);
				localStorage.removeItem("token");
				localStorage.removeItem("admin");
				localStorage.removeItem("hasWordBankAccess");
				localStorage.removeItem("templateAccess");
				localStorage.removeItem("requestAccess");
				localStorage.removeItem("userId");
				localStorage.removeItem("isPublicStorage");
				localStorage.removeItem("reportAccess");
				localStorage.removeItem("username");
				localStorage.removeItem("exportAccess");
				localStorage.removeItem("clearRecordAccess");
				logout({ logoutParams: { returnTo: window.location.origin + "/login" } });
				return;
			}
			setToken(res.token);
			setIsAdmin(res.admin);
			setWordBankAccess(res.word_bank_permission);
			setTemplateAccess(res.template_permission);
			setRequestAccess(res.request_permission);
			setUserId(res.odoo_id);
			setIsPublicStorage(res.is_public_storage);
			setReportAccess(res.report_permission);
			setProposalAccess(res.proposal_permission);
			setExportAccess(res.export_permission);
			setClearRecordAccess(res.clear_record_permission);

			localStorage.setItem("token", res.token);
			localStorage.setItem("admin", res.admin);
			localStorage.setItem("hasWordBankAccess", res.word_bank_permission);
			localStorage.setItem("templateAccess", res.template_permission);
			localStorage.setItem("requestAccess", res.request_permission);
			localStorage.setItem("userId", res.odoo_id);
			localStorage.setItem("isPublicStorage", res.is_public_storage);
			localStorage.setItem("reportAccess", res.report_permission);
			localStorage.setItem("username", res.odoo_name);
			localStorage.setItem("proposalAccess", res.proposal_permission);
			localStorage.setItem("exportAccess", res.export_permission);
			localStorage.setItem("clearRecordAccess", res.clear_record_permission);
		}
	}, [
		isAuthenticated,
		isLoading,
		location.pathname,
		location.search,
		logout,
		navigate,
		setClearRecordAccess,
		setExportAccess,
		setIsAdmin,
		setIsPublicStorage,
		setProposalAccess,
		setReportAccess,
		setRequestAccess,
		setTemplateAccess,
		setToken,
		setUserId,
		setWordBankAccess,
		user,
	]);

	useEffect(() => {
		if (hasUnsavedFormData && lastLocation.pathname.startsWith("/report/") && lastLocation.pathname !== location.pathname) {
			if (window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
				setHasUnsavedFormData(false);
				setLastLocation(location);
				localStorage.removeItem("lastReportFormData");
			} else {
				navigate(lastLocation.pathname + "?unsaved=true");
			}
		} else {
			setLastLocation(location);
		}
	}, [location, lastLocation, navigate, hasUnsavedFormData, setHasUnsavedFormData]);

	useEffect(() => {
		if (isLoading) {
			return;
		}
		if (loggedIn) {
			return;
		}
		if (!isAuthenticated) {
			setIsAdmin(false);
			setToken("");
			setWordBankAccess(false);
			setRequestAccess(false);
			setTemplateAccess(false);
			setUserId(-1);
			setIsPublicStorage(false);
			setReportAccess(false);
			setExportAccess(false);
			setClearRecordAccess(false);
			localStorage.removeItem("token");
			localStorage.removeItem("admin");
			localStorage.removeItem("hasWordBankAccess");
			localStorage.removeItem("templateAccess");
			localStorage.removeItem("requestAccess");
			localStorage.removeItem("userId");
			localStorage.removeItem("isPublicStorage");
			localStorage.removeItem("reportAccess");
			localStorage.removeItem("username");
			localStorage.removeItem("exportAccess");
			localStorage.removeItem("clearRecordAccess");
			logout({ logoutParams: { returnTo: window.location.origin + "/login" } });
			return;
		}
		let tempToken = "";
		if (localStorage.getItem("token")) {
			setToken(localStorage.getItem("token"));
			tempToken = localStorage.getItem("token");
		} else {
			// navigate("/login", { state: { newPath: location.pathname, newSearch: location.search } });
			return;
		}
		if (localStorage.getItem("admin")) {
			setIsAdmin(localStorage.getItem("admin") === "true");
		}

		if (localStorage.getItem("hasWordBankAccess")) {
			setWordBankAccess(localStorage.getItem("hasWordBankAccess") === "true");
		}

		if (localStorage.getItem("templateAccess")) {
			setTemplateAccess(localStorage.getItem("templateAccess") === "true");
		}

		if (localStorage.getItem("requestAccess")) {
			setRequestAccess(localStorage.getItem("requestAccess") === "true");
		}
		if (localStorage.getItem("userId")) {
			setUserId(localStorage.getItem("userId"));
		}
		if (localStorage.getItem("isPublicStorage")) {
			setIsPublicStorage(localStorage.getItem("isPublicStorage") === "true");
		}
		if (localStorage.getItem("reportAccess")) {
			setReportAccess(localStorage.getItem("reportAccess") === "true");
		}
		if (localStorage.getItem("proposalAccess")) {
			setProposalAccess(localStorage.getItem("proposalAccess") === "true");
		}
		if (localStorage.getItem("exportAccess")) {
			setExportAccess(localStorage.getItem("exportAccess") === "true");
		}
		if (localStorage.getItem("clearRecorddAccess")) {
			setClearRecordAccess(localStorage.getItem("clearRecordAccess") === "true");
		}
		setLoading(true);
		const controller = new AbortController();
		const signal = controller.signal;
		if (!loggedIn) {
			fetch(api + "/jwt", {
				method: "GET",
				headers: { Authorization: tempToken },
				signal,
			})
				.then((res) => {
					setLoading(false);
					if (!res.ok) {
						setIsAdmin(false);
						setToken("");
						setWordBankAccess(false);
						setRequestAccess(false);
						setTemplateAccess(false);
						setUserId(-1);
						setIsPublicStorage(false);
						setReportAccess(false);
						setExportAccess(false);
						setClearRecordAccess(false);
						localStorage.removeItem("token");
						localStorage.removeItem("admin");
						localStorage.removeItem("hasWordBankAccess");
						localStorage.removeItem("templateAccess");
						localStorage.removeItem("requestAccess");
						localStorage.removeItem("userId");
						localStorage.removeItem("isPublicStorage");
						localStorage.removeItem("reportAccess");
						localStorage.removeItem("username");
						localStorage.removeItem("exportAccess");
						localStorage.removeItem("clearRecordAccess");
						logout({ logoutParams: { returnTo: window.location.origin + "/login" } });
					} else {
						setLoggedIn(true);
					}
				})
				.catch((err) => {
					setLoading(false);
					setIsAdmin(false);
					setToken("");
					setWordBankAccess(false);
					setRequestAccess(false);
					setTemplateAccess(false);
					setUserId(-1);
					setIsPublicStorage(false);
					setReportAccess(false);
					setExportAccess(false);
					setClearRecordAccess(false);
					localStorage.removeItem("token");
					localStorage.removeItem("admin");
					localStorage.removeItem("hasWordBankAccess");
					localStorage.removeItem("templateAccess");
					localStorage.removeItem("requestAccess");
					localStorage.removeItem("userId");
					localStorage.removeItem("isPublicStorage");
					localStorage.removeItem("reportAccess");
					localStorage.removeItem("username");
					localStorage.removeItem("exportAccess");
					localStorage.removeItem("clearRecordAccess");
					logout({ logoutParams: { returnTo: window.location.origin + "/login" } });
				});
		}
		return () => {
			if (isLoading) {
				setLoading(false);
				controller.abort();
			}
		};
	}, [
		api,
		isAuthenticated,
		isLoading,
		location.pathname,
		location.search,
		loggedIn,
		logout,
		navigate,
		setClearRecordAccess,
		setExportAccess,
		setIsAdmin,
		setIsPublicStorage,
		setProposalAccess,
		setReportAccess,
		setRequestAccess,
		setTemplateAccess,
		setToken,
		setUserId,
		setWordBankAccess,
	]);

	useEffect(() => {
		if (loggedIn && isPublicStorage && (wordBankAccessRequired || requestAccessRequired || templateAccessRequired || location.pathname === "/reports")) {
			navigate("/dashboard");
		} else if (loggedIn) {
			let shouldRedirect = false;
			if ((adminRequired && isAdmin === false) || (adminRequired && isPublicStorage === true)) {
				shouldRedirect = true;
			}
			if (wordBankAccessRequired && wordBankAccess === false && isAdmin === false) {
				shouldRedirect = true;
			}
			if (requestAccessRequired && requestAccess === false && isAdmin === false) {
				shouldRedirect = true;
			}
			if (templateAccessRequired && templateAccess === false && isAdmin === false) {
				shouldRedirect = true;
			}
			if (reportAccessRequired && reportAccess === false && isAdmin === false) {
				shouldRedirect = true;
			}
			if (shouldRedirect) {
				navigate("/dashboard");
			}
		}
	}, [
		adminRequired,
		isAdmin,
		isPublicStorage,
		location.pathname,
		loggedIn,
		navigate,
		reportAccess,
		reportAccessRequired,
		requestAccess,
		requestAccessRequired,
		templateAccess,
		templateAccessRequired,
		wordBankAccess,
		wordBankAccessRequired,
	]);

	const clickBackButton = () => {
		if (typeof hasUnsavedFormData !== "undefined" && hasUnsavedFormData === true) {
			let confirmationResult = window.confirm("You have unsaved changes, are you sure you want to leave?");
			if (confirmationResult === true) {
				setHasUnsavedFormData(false);
				navigate(-1);
			}
		} else {
			navigate(-1);
		}
	};

	return (
		<>
			<Nav
				api={api}
				isAdmin={isAdmin}
				wordBankAccess={wordBankAccess}
				requestAccess={requestAccess}
				templateAccess={templateAccess}
				setTemplateAccess={setTemplateAccess}
				setRequestAccess={setRequestAccess}
				setWordBankAccess={setWordBankAccess}
				setIsAdmin={setIsAdmin}
				setToken={setToken}
				setUserId={setUserId}
				isPublicStorage={isPublicStorage}
				setIsPublicStorage={setIsPublicStorage}
				setReportAccess={setReportAccess}
				reportAccess={reportAccess}
				hasUnsavedFormData={hasUnsavedFormData}
				setHasUnsavedFormData={setHasUnsavedFormData}
			/>
			{api !== process.env.REACT_APP_PRODUCTION_URL ? (
				<div className="p-2 font-bold text-center warning-banner-pulse z-50">
					<p>! This is a testing environment. Your changes will not be saved to the actual site. !</p>
				</div>
			) : null}
			{loading || isLoading ? (
				<div className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black/50 z-50">
					<div className="flex flex-col items-center flex-center rounded-lg gap-3 bg-white p-4">
						<LoadingCircle />
						<p>Loading...</p>
					</div>
				</div>
			) : null}
			<div className="">
				{location.pathname === "/" || location.pathname === "/dashboard" ? null : (
					<div className="p-3 flex items-center gap-2 bg-gray-300 ">
						<IoArrowBackCircle className="text-3xl cursor-pointer" onClick={() => clickBackButton()} />
						<p className="cursor-pointer" onClick={() => clickBackButton()}>
							Go back
						</p>
					</div>
				)}

				{loading || isLoading ? null : <Outlet />}
			</div>
		</>
	);
};

export default WrapperRoute;
