import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import LoadingCircle from "../utility/LoadingCircle";

const Test = () => {
	const { loginWithRedirect, isAuthenticated, user, logout, isLoading } = useAuth0();

	return (
		<div className="fixed top-0 left-0 right-0 bottom-0 bg-black">
			<div className="flex w-full h-full justify-center items-center">
				<button className="text-white bg-red" onClick={() => logout({ logoutParams: { returnTo: window.location.origin + "/login" } })}>
					Log Out
				</button>
			</div>
		</div>
	);
};

export default Test;
